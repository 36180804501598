import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({
  author,
  description,
  lang,
  meta,
  keywords,
  title,
  url,
  pathname,
  image: metaImage,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = url || data.site.siteMetadata.url
        const image =
    metaImage && metaImage.src
      ? `${data.site.siteMetadata.url}${metaImage.src}`
      : null

        const metaKeywords = keywords || [
          "Pramri",
          "false ceiling",
          "Tips for false ceiling",
          "Interior Design",
          "Interior Design planning",
          "Gypsum vs POP",
          "Different types of False Ceiling",
          "Modular Kitchen",
          "Kitchen Design",
          "House Painting",
          "Kitchen Laminates",
          "PVC Laminates",
          "Laminates Selection",
          "Small house Design",
          "Small master bedroom design",
          "Master Bedroom design",
          "Woodwork",
          "Home Interiors",
          "tags",
          "Pramri Interiors tags",
          "false ceiling in hindi",
          "Tips for false ceiling in hindi",
          "Interior Design in hindi",
          "Interior Design planning in hindi",
          "Gypsum vs POP in hindi ",
          "Different types of False Ceiling in hindi",
          "Modular Kitchen in hindi",
          "Kitchen Design in hindi ",
          "House Painting in hindi",
          "Kitchen Laminates in hindi",
          "PVC Laminates in hindi",
          "Laminates Selection in hindi",
          "Small house Design in hindi",
          "Small master bedroom design in hindi",
          "Master Bedroom design in hindi",
          "Woodwork in hindi",
          "Home Interiors in hindi",
          "Plywood aur blackboard me kaun sa better hai"
        ]

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            // titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                name: 'title',
                content: metaTitle,
              },
              {
                property: 'og:title',
                content: metaTitle,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'og:url',
                content: pathname ? url + pathname : url,
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: author,
              },
              {
                name: 'twitter:title',
                content: metaTitle,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
            ]
            .concat(
              metaImage
                ? [
                    {
                      property: "og:image",
                      content: image,
                    },
                    {
                      property: "og:image:width",
                      content: metaImage.width,
                    },
                    {
                      property: "og:image:height",
                      content: metaImage.height,
                    },
                    {
                      name: "twitter:card",
                      content: "summary_large_image",
                    },
                  ]
                : [
                    {
                      name: "twitter:card",
                      content: "summary",
                    },
                  ]
            )
              .concat(
                metaKeywords && metaKeywords.length > 0
                  ? {
                      name: 'keywords',
                      content: metaKeywords.join(', '),
                    }
                  : []
              )
             .concat(meta)
            }
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}

export default SEO

export const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

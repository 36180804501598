import React from 'react'
const Footer = () => (
<div className="site-footer">
    <h4 className="text-center" color="red">
        Interior Design Blog 
    </h4>

    <p className="text-center"> © 2021 PRAMRI</p>
    <div className="footer-social-links">
        <ul className="social-links-list">
            <li> <a href="https://www.youtube.com/channel/UCkMSooIIGsUGgt86mkPq1mg" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="Youtube">
                <i className="fab fa-facebook fa-2x"/>
                </a></li>
        </ul>
    </div>
</div>
)
export default Footer
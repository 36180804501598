
import PropTypes from "prop-types"
import React from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';


  class Header extends React.Component {
    constructor(props){
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = {
        isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render(){
    return (
      <Navbar fixed="top" expand="sm" light>
        <div className="container">
        <NavbarBrand href="/">{this.props.siteTitle}</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/woodwork">Woodwork</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/falseceiling">False Ceiling</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/bedroom">Bedroom</NavLink>
            </NavItem>
             <NavItem>
              <NavLink href="/kitchen">Kitchen</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="/budgetCalculator">Budget Calculator</NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink href="/tags">Tags</NavLink>
            </NavItem> */}

            {/* <NavItem>
              <NavLink href="/design3d">3D Design</NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink href="/covid">Covid Vaccine Availability</NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink href="/contact">Contact Us</NavLink>
            </NavItem>

          </Nav>
        </Collapse>
        </div>
      </Navbar>
   );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Pramri`,
}

export default Header
